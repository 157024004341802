import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getCurrentFilteredState,
  getTransitionsToState,
  getCurrentFilteredListingId,
  getCurrentFilteredDateOne,
  getCurrentFilteredDateTwo
} from './components/SectionInboxFilters/utils';
import { graph, transitions } from '../../transactions/transactionProcessSimplePurchase';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const getFirstOfSeptember = () => {
  const today = moment();
  const currentYear = today.year();

  // Create a moment object for the first of September of the current year
  const firstOfSeptemberThisYear = moment([currentYear, 8, 1]); // Month is 0-based, so 8 is September

  // Check if today's date is before September 1st
  if (today.isBefore(firstOfSeptemberThisYear)) {
    // If before September 1st, return September 1st of the previous year
    return moment([currentYear - 1, 8, 1]);
  } else {
    // If on or after September 1st, return September 1st of the current year
    return firstOfSeptemberThisYear;
  }
}

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  ownEntities: [],
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
        ownEntities: payload.ownEntities,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;
  const currentSearchedState = getCurrentFilteredState(search);
  const listingId = getCurrentFilteredListingId(search);
  const selectedDateOne = getCurrentFilteredDateOne(search) || getFirstOfSeptember().toISOString();
  const selectedDateTwo = getCurrentFilteredDateTwo(search) || moment().toISOString();
  const statesData = getTransitionsToState(graph?.states);
  const stateList = currentSearchedState ? currentSearchedState.split(',') : [];
  let lastTransitions = getAllTransitionsForEveryProcess();

  if (currentSearchedState) {
    let str = statesData.filter(s => stateList.includes(s?.label)).map(({ transitions }) => transitions).join(',');

    if (stateList.includes('received')) {
      str += `${str ? ',' : ''}${transitions.AUTO_MARK_RECEIVED},${transitions.OPERATOER_MARK_RECEIVED},${transitions.PROVIDER_MARK_RECEIVED}`
    }

    if (stateList.includes('confirm')) {
      str += `${str ? ',' : ''}${transitions.CONFIRM_PAYMENT}`
    }

    if (str) {
      lastTransitions = str.split(',');
    }
  }

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions,
    createdAtStart: selectedDateOne,
    createdAtEnd: selectedDateTwo,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData'
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  if (listingId) {
    apiQueryParams.listingId = listingId;
  }

  return sdk.transactions
    .query(apiQueryParams)
    .then(async response => {
      await sdk.ownListings
        .query({})
        .then(res => {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchOrdersOrSalesSuccess({
            ...response,
            ownEntities: res.data.data.map((d) => ({
              title: d.attributes.title,
              uuid: d.id.uuid
            }))
          }));
        })

      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const getAllTransaction = () => {
  return async (dispatch, _getState, sdk) => {
    const itemsPerPage = 100;
    const search = window.location.search;
    const currentSearchedState = getCurrentFilteredState(search);
    const listingId = getCurrentFilteredListingId(search);
    const selectedDateOne = getCurrentFilteredDateOne(search) || getFirstOfSeptember().toISOString();
    const selectedDateTwo = getCurrentFilteredDateTwo(search) || moment().toISOString();
    const statesData = getTransitionsToState(graph?.states);
    const stateList = currentSearchedState ? currentSearchedState.split(',') : [];
    let lastTransitions = getAllTransitionsForEveryProcess();

    if (currentSearchedState) {
      let str = statesData.filter(s => stateList.includes(s?.label)).map(({ transitions }) => transitions).join(',');

      if (stateList.includes('received')) {
        str += `${str ? ',' : ''}${transitions.AUTO_MARK_RECEIVED},${transitions.OPERATOER_MARK_RECEIVED},${transitions.PROVIDER_MARK_RECEIVED}`
      }

      if (stateList.includes('confirm')) {
        str += `${str ? ',' : ''}${transitions.CONFIRM_PAYMENT}`
      }

      if (str) {
        lastTransitions = str.split(',');
      }
    }

    const apiQueryParams = {
      only: 'sale',
      lastTransitions,
      createdAtStart: selectedDateOne,
      createdAtEnd: selectedDateTwo,
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
        'protectedData'
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      page: 1,
      perPage: 1,
    };

    if (listingId) {
      apiQueryParams.listingId = listingId;
    }

    const { data: { meta: { totalItems } } } = await sdk.transactions.query(apiQueryParams);
    const totalPage = Math.ceil(totalItems/itemsPerPage);
    let promies = [];

    apiQueryParams.perPage = itemsPerPage;
    for (let i = 0; i < totalPage; i++) {
      promies.push(sdk.transactions.query({ ...apiQueryParams, page: i + 1 }));
    }

    return await Promise.all(promies).then(async (allRes) => {
      let response = allRes[0];
      for (let i = 1; i < allRes.length; i++) {
        response.data.data = response.data.data.concat(allRes[i].data.data);
      }

      const userIds = [...new Set(response.data.data.map(({ relationships: { customer } }) => customer.data.id.uuid))];
      const usersInfo = await Promise.all(userIds.map((id) => {
        return sdk.users
          .show({
            id,
            include: ['profileImage', 'publicData'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
          })
      }));
      const listing = await sdk.ownListings.query({});

      console.log(response);

      return {
        transactions: response,
        users: usersInfo,
        listing
      };
    }).catch(e => {
      throw e;
    });
  };
};
